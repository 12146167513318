/* eslint no-console:0 */

import Turbolinks from 'turbolinks'
import '../assets/stylesheets/session.scss'

/**
 * Start Rails js engine
 */

/**
 * Start Turbolinks
 */
Turbolinks.start()
